<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-account-plus</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>Praktika</v-toolbar-title>
      <v-spacer />
      <SearchField v-model="search" hint="Name oder Kürzel"></SearchField>
      <template v-slot:extension>
        <TermTabs align-with-title v-model="term" />
        <v-tabs>
          <v-tabs-slider :color="current ? 'error' : 'primary'"></v-tabs-slider>
          <v-tab @click="toggleCurrent">aktuelle</v-tab>
        </v-tabs>
        <v-btn @click="add" absolute bottom right color="success" fab>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
    </v-app-bar>
    <v-card>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :search="search"
        :sort-by="'startDate'"
        :custom-filter="filter"
        :item-class="() => 'clickable'"
        @click:row="(item) => showDetails(item.id)"
      >
        <template v-slot:item.teacher="{ item }">
          <PersonItem clickable :value="item.teacher" />
        </template>
        <template v-slot:item.startDate="{ item }">
          <DateValue :value="item.startDate" />
        </template>
        <template v-slot:item.endDate="{ item }">
          <DateValue :value="item.endDate" />
        </template>
        <template v-slot:item.intern="{ item }">
          <PersonItem clickable :value="item.intern" />
        </template>
      </v-data-table>
    </v-card>
    <router-view></router-view>
  </v-container>
</template>
<script>
import { comparePeople, searchPerson } from "common/utils/people.js";
import { today } from "common/utils/date.js";
import DateValue from "common/components/DateValue.vue";
import PersonItem from "@/components/PersonItem.vue";
import SearchField from "@/components/SearchField.vue";
import TermTabs from "@/components/TermTabs.vue";

export default {
  name: "Absences",
  components: {
    DateValue,
    PersonItem,
    SearchField,
    TermTabs,
  },
  data() {
    return {
      current: false,
      loading: false,
      search: "",
      headers: [
        { text: "Praktikant*in", value: "intern", sort: comparePeople },
        { text: "von", value: "startDate" },
        { text: "bis", value: "endDate" },
        { text: "bei Lehrer*in", value: "teacher", sort: comparePeople },
      ],
      items: [],
      term: null,
    };
  },
  watch: {
    term() {
      this.getData();
    },
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === "Stages") {
      this.getData();
    }
    next();
  },
  methods: {
    add() {
      this.$router.push({
        name: "StageDetails",
        params: { id: "new" },
      });
    },
    async getData() {
      if (!this.term && !this.current) return;
      const startDate = this.current ? today() : this.term.startDate;
      const endDate = this.current ? today() : this.term.endDate;
      this.loading = true;
      this.items = await this.apiList({
        resource: "teacher/stage",
        query: `startDate=${startDate}&endDate=${endDate}`,
      });
      this.loading = false;
    },
    showDetails(id) {
      this.$router.push({
        name: "StageDetails",
        params: { id: id },
      });
    },
    toggleCurrent() {
      this.current = !this.current;
      this.getData();
    },
    filter(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "object" &&
        searchPerson(value, search)
      );
    },
  },
};
</script>
